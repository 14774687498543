


@font-face {
  font-family: "CormGara";
  src: local("CormGara"),
    url("./fonts/CormorantGaramond-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "CormGara";
  src: local("CormGara"),
    url("./fonts/CormorantGaramond-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "CormGara";
  src: local("CormGara"),
    url("./fonts/CormorantGaramond-Italic.ttf") format("truetype");
  font-style: italic;
}


body,html  {
  margin: 0;
  font-family: "CormGara";
  font-size: 1.1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

h1, h2, h3, h4, h5, h6, body, html, p, div {
  font-family: 'CormGara', 'Times New Roman', Times, serif;
}