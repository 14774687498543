.destyle, a.destyle:hover, a.destyle:focus, a.destyle:active, a.destyle:visited  {
  color: inherit;
  text-decoration: none;
}

/* Padding */
.padTen {
  padding: 10px;
}

.centered {
  text-align: center;
}

/* Footer Classes */
.footer {
  padding: 15px;
  line-height: 1.5rem;
}

/* small gold text adjusts */
.smallGold {
  font-size: 0.8rem !important;
}

.smallHead {
  font-size: 1rem !important;
}

.greenBut {
  background-color: #183c3a !important;
}

.greenBG {
  /*background-color: #0e2c18;*/
  background-color: #183c3a;
  color: #fff;
}

.greenBGlight {
  background-color: #d0ebe9ee;
  color: #000;
}

.tanBG {
  background-color: #ede2d2;
}

/* Header Styles */
.headerSection {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 1000px;
}

/* Line Separators */
.lineSep {
  border-top: 3px dotted #bf9e77;
  display: flex;
  flex-grow: 1;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.lineSepGreen {
  /* 
  border-top: 3px dotted #0e2c18;
  */
  border-top: 3px dotted #183c3a;
  display: flex;
  flex-grow: 1;
  min-width: 100px;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

/* Line Separators */
.lineSepW {
  border-top: 3px dotted #bf9e77;
  display: flex;
  flex-grow: 1;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}



/* Become a Member - Footer */
.becomeAMember, .becomeAMember a {
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bf9e77;
  color: #000;
  font-size: 2rem;
  text-decoration: none;
}

.dogAnim {
  animation: 6s sideAnim infinite alternate;
  position: absolute;
}

.catAnim {
  animation: 5s catAnim infinite alternate;
  position: absolute;
  z-index: 1;
}

/* Dynamic BG 
@media only screen and (max-width: 900px) {
  .dynMobileBG {
    background-color: #ede2d2;
  }
}
*/

/* Responsive BG */
@media only screen and (max-width: 600px) {
  .backgroundImage {
    right: -300px!important;
  }

  .catAnim {
    animation: 5s catAnimMobile infinite alternate;
  }
  .dogAnim {
    animation: 6s sideAnimMobile infinite alternate;
  }

  .dynFlex {
    display: flex;
    flex-direction: column;
  }
}

@keyframes sideAnimMobile {
  0% {
    left: 0;
  } 
  
  100% {
    left: 50px;
  }
}

@keyframes catAnimMobile {
  0% {
    left: 0px;
  } 
  
  100% {
    left: -100px;
  }
}

@keyframes sideAnim {
  0% {
    left: 0;
  } 
  
  100% {
    left: 70vw;
  }
}

@keyframes catAnim {
  0% {
    left: 0px;
  } 
  
  100% {
    left: 350px;
  }
}


.iconStyle {
  text-align: center;
  padding: 20px;
}

.headerStyle {
  text-align: center;
  color: #bf9e77;
  font-size: 1.2em;
  font-weight: bold;
}

.goldText {
  color: #bf9e77;
}

.textStyle {
  text-align: center;
  padding: 10px;
  line-height: 1.5em;
  font-size: 0.9rem;
}

/* Bottom Image Adjustments */
.bottomImage {
  position: absolute;
}

.padTen {
  padding: 10px;
}

.padLTen {
  padding-left: 10px;
}

@media only screen and (max-width: 600px) {
  .flexGMob {
    flex-grow: 1;
  }
  .resizeText {
    font-size: 0.8rem;
  }
  .bottomImage {
    transform: translateX(-420px); /* bottom contact form image */
  }
  .dynVid {
    /* width: 100%; */
    transform: translateX(-1000px);
  }
}


@media only screen and (min-width: 601px) and (max-width: 900px) {
  .dynVid {
    /*width: 100%; */
    transform: translateX(-900px);
  }
  .bottomImage {
    transform: translateX(-500px); /* bottom contact form image */
  }
}

@media only screen and (min-width: 901px) and (max-width: 1300px)  {
  .dynVid {
    height: 100%; 
    transform: translateX(-300px); 
  }
}


@media only screen and (min-width: 1301px) and (max-width: 1800px)  {
  .dynVid {
    height: 100%; 
  }
}

@media only screen and (min-width: 1801px) and (max-width: 2300px) {
  .dynVid {
    width: 100%; 
  }
  .bottomImage{
    transform: scale(1.5);
  }
}

@media only screen and (min-width: 2301px) {
  .dynVid {
    width: 100% !important; 
  }
  .bottomImage {
    transform: scale(3);
  }
}


@media only screen and (max-width: 900px) {
  .additionalDyn {
    width: 100%;
  }
}


/* Dyn Class for text */
.darkText {
  color: #000;
}

.RobotoFont {
  font-family: Roboto;
}