.et_animated {
    opacity: 0;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both!important;
    animation-fill-mode: both!important
}

.et_animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

.et_had_animation {
    position: relative
}

@-webkit-keyframes et_pb_fade {
    to {
        opacity: 1
    }
}

@keyframes et_pb_fade {
    to {
        opacity: 1
    }
}

.et_animated.fade {
    -webkit-animation-name: et_pb_fade;
    animation-name: et_pb_fade
}

@-webkit-keyframes et_pb_fadeTop {
    0% {
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes et_pb_fadeTop {
    0% {
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.et_animated.fadeTop {
    -webkit-animation-name: et_pb_fadeTop;
    animation-name: et_pb_fadeTop
}

@-webkit-keyframes et_pb_fadeRight {
    0% {
        -webkit-transform: translate3d(100%,0,0);
        transform: translate3d(100%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes et_pb_fadeRight {
    0% {
        -webkit-transform: translate3d(100%,0,0);
        transform: translate3d(100%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.et_animated.fadeRight {
    -webkit-animation-name: et_pb_fadeRight;
    animation-name: et_pb_fadeRight
}

@-webkit-keyframes et_pb_fadeBottom {
    0% {
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes et_pb_fadeBottom {
    0% {
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.et_animated.fadeBottom {
    -webkit-animation-name: et_pb_fadeBottom;
    animation-name: et_pb_fadeBottom
}

@-webkit-keyframes et_pb_fadeLeft {
    0% {
        -webkit-transform: translate3d(-100%,0,0);
        transform: translate3d(-100%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes et_pb_fadeLeft {
    0% {
        -webkit-transform: translate3d(-100%,0,0);
        transform: translate3d(-100%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.et_animated.fadeLeft {
    -webkit-animation-name: et_pb_fadeLeft;
    animation-name: et_pb_fadeLeft
}

@-webkit-keyframes et_pb_slide {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

@keyframes et_pb_slide {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

.et_animated.slide {
    -webkit-animation-name: et_pb_slide;
    animation-name: et_pb_slide
}

@-webkit-keyframes et_pb_slideTop {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes et_pb_slideTop {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

.et_animated.slideTop {
    -webkit-animation-name: et_pb_slideTop;
    animation-name: et_pb_slideTop
}

@-webkit-keyframes et_pb_slideRight {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes et_pb_slideRight {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

.et_animated.slideRight {
    -webkit-animation-name: et_pb_slideRight;
    animation-name: et_pb_slideRight
}

@-webkit-keyframes et_pb_slideBottom {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes et_pb_slideBottom {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

.et_animated.slideBottom {
    -webkit-animation-name: et_pb_slideBottom;
    animation-name: et_pb_slideBottom
}

@-webkit-keyframes et_pb_slideLeft {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes et_pb_slideLeft {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

.et_animated.slideLeft {
    -webkit-animation-name: et_pb_slideLeft;
    animation-name: et_pb_slideLeft
}

@-webkit-keyframes et_pb_bounce {
    0%,20%,40%,60%,80%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: scale3d(.3,.3,.3);
        transform: scale3d(.3,.3,.3)
    }

    20% {
        -webkit-transform: scale3d(1.1,1.1,1.1);
        transform: scale3d(1.1,1.1,1.1)
    }

    40% {
        -webkit-transform: scale3d(.9,.9,.9);
        transform: scale3d(.9,.9,.9)
    }

    60% {
        -webkit-transform: scale3d(1.03,1.03,1.03);
        transform: scale3d(1.03,1.03,1.03)
    }

    80% {
        -webkit-transform: scale3d(.97,.97,.97);
        transform: scale3d(.97,.97,.97)
    }

    to {
        opacity: 1;
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
}

@keyframes et_pb_bounce {
    0%,20%,40%,60%,80%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: scale3d(.3,.3,.3);
        transform: scale3d(.3,.3,.3)
    }

    20% {
        -webkit-transform: scale3d(1.1,1.1,1.1);
        transform: scale3d(1.1,1.1,1.1)
    }

    40% {
        -webkit-transform: scale3d(.9,.9,.9);
        transform: scale3d(.9,.9,.9)
    }

    60% {
        -webkit-transform: scale3d(1.03,1.03,1.03);
        transform: scale3d(1.03,1.03,1.03)
    }

    80% {
        -webkit-transform: scale3d(.97,.97,.97);
        transform: scale3d(.97,.97,.97)
    }

    to {
        opacity: 1;
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
}

.et_animated.bounce {
    -webkit-animation-name: et_pb_bounce;
    animation-name: et_pb_bounce
}

@-webkit-keyframes et_pb_bounceTop {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(0,-200px,0);
        transform: translate3d(0,-200px,0)
    }

    60% {
        -webkit-transform: translate3d(0,25px,0);
        transform: translate3d(0,25px,0)
    }

    75% {
        -webkit-transform: translate3d(0,-10px,0);
        transform: translate3d(0,-10px,0)
    }

    90% {
        -webkit-transform: translate3d(0,5px,0);
        transform: translate3d(0,5px,0)
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_bounceTop {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(0,-200px,0);
        transform: translate3d(0,-200px,0)
    }

    60% {
        -webkit-transform: translate3d(0,25px,0);
        transform: translate3d(0,25px,0)
    }

    75% {
        -webkit-transform: translate3d(0,-10px,0);
        transform: translate3d(0,-10px,0)
    }

    90% {
        -webkit-transform: translate3d(0,5px,0);
        transform: translate3d(0,5px,0)
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.bounceTop {
    -webkit-animation-name: et_pb_bounceTop;
    animation-name: et_pb_bounceTop
}

@-webkit-keyframes et_pb_bounceRight {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(200px,0,0);
        transform: translate3d(200px,0,0)
    }

    60% {
        -webkit-transform: translate3d(-25px,0,0);
        transform: translate3d(-25px,0,0)
    }

    75% {
        -webkit-transform: translate3d(10px,0,0);
        transform: translate3d(10px,0,0)
    }

    90% {
        -webkit-transform: translate3d(-5px,0,0);
        transform: translate3d(-5px,0,0)
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_bounceRight {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(200px,0,0);
        transform: translate3d(200px,0,0)
    }

    60% {
        -webkit-transform: translate3d(-25px,0,0);
        transform: translate3d(-25px,0,0)
    }

    75% {
        -webkit-transform: translate3d(10px,0,0);
        transform: translate3d(10px,0,0)
    }

    90% {
        -webkit-transform: translate3d(-5px,0,0);
        transform: translate3d(-5px,0,0)
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.bounceRight {
    -webkit-animation-name: et_pb_bounceRight;
    animation-name: et_pb_bounceRight
}

@-webkit-keyframes et_pb_bounceBottom {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(0,200px,0);
        transform: translate3d(0,200px,0)
    }

    60% {
        -webkit-transform: translate3d(0,-20px,0);
        transform: translate3d(0,-20px,0)
    }

    75% {
        -webkit-transform: translate3d(0,10px,0);
        transform: translate3d(0,10px,0)
    }

    90% {
        -webkit-transform: translate3d(0,-5px,0);
        transform: translate3d(0,-5px,0)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes et_pb_bounceBottom {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(0,200px,0);
        transform: translate3d(0,200px,0)
    }

    60% {
        -webkit-transform: translate3d(0,-20px,0);
        transform: translate3d(0,-20px,0)
    }

    75% {
        -webkit-transform: translate3d(0,10px,0);
        transform: translate3d(0,10px,0)
    }

    90% {
        -webkit-transform: translate3d(0,-5px,0);
        transform: translate3d(0,-5px,0)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

.et_animated.bounceBottom {
    -webkit-animation-name: et_pb_bounceBottom;
    animation-name: et_pb_bounceBottom
}

@-webkit-keyframes et_pb_bounceLeft {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(-200px,0,0);
        transform: translate3d(-200px,0,0)
    }

    60% {
        -webkit-transform: translate3d(25px,0,0);
        transform: translate3d(25px,0,0)
    }

    75% {
        -webkit-transform: translate3d(-10px,0,0);
        transform: translate3d(-10px,0,0)
    }

    90% {
        -webkit-transform: translate3d(5px,0,0);
        transform: translate3d(5px,0,0)
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_bounceLeft {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(-200px,0,0);
        transform: translate3d(-200px,0,0)
    }

    60% {
        -webkit-transform: translate3d(25px,0,0);
        transform: translate3d(25px,0,0)
    }

    75% {
        -webkit-transform: translate3d(-10px,0,0);
        transform: translate3d(-10px,0,0)
    }

    90% {
        -webkit-transform: translate3d(5px,0,0);
        transform: translate3d(5px,0,0)
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.bounceLeft {
    -webkit-animation-name: et_pb_bounceLeft;
    animation-name: et_pb_bounceLeft
}

@-webkit-keyframes et_pb_zoom {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

@keyframes et_pb_zoom {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

.et_animated.zoom {
    -webkit-animation-name: et_pb_zoom;
    animation-name: et_pb_zoom
}

@-webkit-keyframes et_pb_zoomTop {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

@keyframes et_pb_zoomTop {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

.et_animated.zoomTop {
    -webkit-animation-name: et_pb_zoomTop;
    animation-name: et_pb_zoomTop;
    -webkit-transform-origin: top;
    transform-origin: top
}

@-webkit-keyframes et_pb_zoomRight {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

@keyframes et_pb_zoomRight {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

.et_animated.zoomRight {
    -webkit-animation-name: et_pb_zoomRight;
    animation-name: et_pb_zoomRight;
    -webkit-transform-origin: right;
    transform-origin: right
}

@-webkit-keyframes et_pb_zoomBottom {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

@keyframes et_pb_zoomBottom {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

.et_animated.zoomBottom {
    -webkit-animation-name: et_pb_zoomBottom;
    animation-name: et_pb_zoomBottom;
    -webkit-transform-origin: bottom;
    transform-origin: bottom
}

@-webkit-keyframes et_pb_zoomLeft {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

@keyframes et_pb_zoomLeft {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

.et_animated.zoomLeft {
    -webkit-animation-name: et_pb_zoomLeft;
    animation-name: et_pb_zoomLeft;
    -webkit-transform-origin: left;
    transform-origin: left
}

@-webkit-keyframes et_pb_flip {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg)
    }
}

@keyframes et_pb_flip {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg)
    }
}

.et_animated.flip {
    -webkit-animation-name: et_pb_flip;
    animation-name: et_pb_flip
}

@-webkit-keyframes et_pb_flipTop {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg)
    }
}

@keyframes et_pb_flipTop {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg)
    }
}

.et_animated.flipTop {
    -webkit-animation-name: et_pb_flipTop;
    animation-name: et_pb_flipTop;
    -webkit-transform-origin: center;
    transform-origin: center
}

@-webkit-keyframes et_pb_flipRight {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg)
    }
}

@keyframes et_pb_flipRight {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg)
    }
}

.et_animated.flipRight {
    -webkit-animation-name: et_pb_flipRight;
    animation-name: et_pb_flipRight;
    -webkit-transform-origin: center;
    transform-origin: center
}

@-webkit-keyframes et_pb_flipBottom {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg)
    }
}

@keyframes et_pb_flipBottom {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg)
    }
}

.et_animated.flipBottom {
    -webkit-animation-name: et_pb_flipBottom;
    animation-name: et_pb_flipBottom;
    -webkit-transform-origin: center;
    transform-origin: center
}

@-webkit-keyframes et_pb_flipLeft {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg)
    }
}

@keyframes et_pb_flipLeft {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg)
    }
}

.et_animated.flipLeft {
    -webkit-animation-name: et_pb_flipLeft;
    animation-name: et_pb_flipLeft;
    -webkit-transform-origin: center;
    transform-origin: center
}

@-webkit-keyframes et_pb_fold {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg)
    }
}

@keyframes et_pb_fold {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg)
    }
}

.et_animated.fold {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-animation-name: et_pb_fold;
    animation-name: et_pb_fold
}

@-webkit-keyframes et_pb_foldTop {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg)
    }
}

@keyframes et_pb_foldTop {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg)
    }
}

.et_animated.foldTop {
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-animation-name: et_pb_foldTop;
    animation-name: et_pb_foldTop
}

@-webkit-keyframes et_pb_foldRight {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg)
    }
}

@keyframes et_pb_foldRight {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg)
    }
}

.et_animated.foldRight {
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-animation-name: et_pb_foldRight;
    animation-name: et_pb_foldRight
}

@-webkit-keyframes et_pb_foldBottom {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg)
    }
}

@keyframes et_pb_foldBottom {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg)
    }
}

.et_animated.foldBottom {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-animation-name: et_pb_foldBottom;
    animation-name: et_pb_foldBottom
}

@-webkit-keyframes et_pb_foldLeft {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg)
    }
}

@keyframes et_pb_foldLeft {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg)
    }
}

.et_animated.foldLeft {
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-animation-name: et_pb_foldLeft;
    animation-name: et_pb_foldLeft
}

@-webkit-keyframes et_pb_roll {
    0% {
        -webkit-transform-origin: center;
        transform-origin: center
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_roll {
    0% {
        -webkit-transform-origin: center;
        transform-origin: center
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.roll {
    -webkit-animation-name: et_pb_roll;
    animation-name: et_pb_roll
}

@-webkit-keyframes et_pb_rollTop {
    0% {
        -webkit-transform-origin: top;
        transform-origin: top
    }

    to {
        -webkit-transform-origin: top;
        transform-origin: top;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_rollTop {
    0% {
        -webkit-transform-origin: top;
        transform-origin: top
    }

    to {
        -webkit-transform-origin: top;
        transform-origin: top;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.rollTop {
    -webkit-animation-name: et_pb_rollTop;
    animation-name: et_pb_rollTop
}

@-webkit-keyframes et_pb_rollRight {
    0% {
        -webkit-transform-origin: right;
        transform-origin: right
    }

    to {
        -webkit-transform-origin: right;
        transform-origin: right;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_rollRight {
    0% {
        -webkit-transform-origin: right;
        transform-origin: right
    }

    to {
        -webkit-transform-origin: right;
        transform-origin: right;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.rollRight {
    -webkit-animation-name: et_pb_rollRight;
    animation-name: et_pb_rollRight
}

@-webkit-keyframes et_pb_rollBottom {
    0% {
        -webkit-transform-origin: bottom;
        transform-origin: bottom
    }

    to {
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_rollBottom {
    0% {
        -webkit-transform-origin: bottom;
        transform-origin: bottom
    }

    to {
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.rollBottom {
    -webkit-animation-name: et_pb_rollBottom;
    animation-name: et_pb_rollBottom
}

@-webkit-keyframes et_pb_rollLeft {
    0% {
        -webkit-transform-origin: left;
        transform-origin: left
    }

    to {
        -webkit-transform-origin: left;
        transform-origin: left;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_rollLeft {
    0% {
        -webkit-transform-origin: left;
        transform-origin: left
    }

    to {
        -webkit-transform-origin: left;
        transform-origin: left;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.rollLeft {
    -webkit-animation-name: et_pb_rollLeft;
    animation-name: et_pb_rollLeft
}

.et_animated {
    opacity: 0;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both!important;
    animation-fill-mode: both!important
}

.et_animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

.et_had_animation {
    position: relative
}

@-webkit-keyframes et_pb_fade {
    to {
        opacity: 1
    }
}

@keyframes et_pb_fade {
    to {
        opacity: 1
    }
}

.et_animated.fade {
    -webkit-animation-name: et_pb_fade;
    animation-name: et_pb_fade
}

@-webkit-keyframes et_pb_fadeTop {
    0% {
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes et_pb_fadeTop {
    0% {
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.et_animated.fadeTop {
    -webkit-animation-name: et_pb_fadeTop;
    animation-name: et_pb_fadeTop
}

@-webkit-keyframes et_pb_fadeRight {
    0% {
        -webkit-transform: translate3d(100%,0,0);
        transform: translate3d(100%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes et_pb_fadeRight {
    0% {
        -webkit-transform: translate3d(100%,0,0);
        transform: translate3d(100%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.et_animated.fadeRight {
    -webkit-animation-name: et_pb_fadeRight;
    animation-name: et_pb_fadeRight
}

@-webkit-keyframes et_pb_fadeBottom {
    0% {
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes et_pb_fadeBottom {
    0% {
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.et_animated.fadeBottom {
    -webkit-animation-name: et_pb_fadeBottom;
    animation-name: et_pb_fadeBottom
}

@-webkit-keyframes et_pb_fadeLeft {
    0% {
        -webkit-transform: translate3d(-100%,0,0);
        transform: translate3d(-100%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes et_pb_fadeLeft {
    0% {
        -webkit-transform: translate3d(-100%,0,0);
        transform: translate3d(-100%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.et_animated.fadeLeft {
    -webkit-animation-name: et_pb_fadeLeft;
    animation-name: et_pb_fadeLeft
}

@-webkit-keyframes et_pb_slide {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

@keyframes et_pb_slide {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

.et_animated.slide {
    -webkit-animation-name: et_pb_slide;
    animation-name: et_pb_slide
}

@-webkit-keyframes et_pb_slideTop {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes et_pb_slideTop {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

.et_animated.slideTop {
    -webkit-animation-name: et_pb_slideTop;
    animation-name: et_pb_slideTop
}

@-webkit-keyframes et_pb_slideRight {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes et_pb_slideRight {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

.et_animated.slideRight {
    -webkit-animation-name: et_pb_slideRight;
    animation-name: et_pb_slideRight
}

@-webkit-keyframes et_pb_slideBottom {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes et_pb_slideBottom {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

.et_animated.slideBottom {
    -webkit-animation-name: et_pb_slideBottom;
    animation-name: et_pb_slideBottom
}

@-webkit-keyframes et_pb_slideLeft {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes et_pb_slideLeft {
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

.et_animated.slideLeft {
    -webkit-animation-name: et_pb_slideLeft;
    animation-name: et_pb_slideLeft
}

@-webkit-keyframes et_pb_bounce {
    0%,20%,40%,60%,80%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: scale3d(.3,.3,.3);
        transform: scale3d(.3,.3,.3)
    }

    20% {
        -webkit-transform: scale3d(1.1,1.1,1.1);
        transform: scale3d(1.1,1.1,1.1)
    }

    40% {
        -webkit-transform: scale3d(.9,.9,.9);
        transform: scale3d(.9,.9,.9)
    }

    60% {
        -webkit-transform: scale3d(1.03,1.03,1.03);
        transform: scale3d(1.03,1.03,1.03)
    }

    80% {
        -webkit-transform: scale3d(.97,.97,.97);
        transform: scale3d(.97,.97,.97)
    }

    to {
        opacity: 1;
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
}

@keyframes et_pb_bounce {
    0%,20%,40%,60%,80%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: scale3d(.3,.3,.3);
        transform: scale3d(.3,.3,.3)
    }

    20% {
        -webkit-transform: scale3d(1.1,1.1,1.1);
        transform: scale3d(1.1,1.1,1.1)
    }

    40% {
        -webkit-transform: scale3d(.9,.9,.9);
        transform: scale3d(.9,.9,.9)
    }

    60% {
        -webkit-transform: scale3d(1.03,1.03,1.03);
        transform: scale3d(1.03,1.03,1.03)
    }

    80% {
        -webkit-transform: scale3d(.97,.97,.97);
        transform: scale3d(.97,.97,.97)
    }

    to {
        opacity: 1;
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
}

.et_animated.bounce {
    -webkit-animation-name: et_pb_bounce;
    animation-name: et_pb_bounce
}

@-webkit-keyframes et_pb_bounceTop {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(0,-200px,0);
        transform: translate3d(0,-200px,0)
    }

    60% {
        -webkit-transform: translate3d(0,25px,0);
        transform: translate3d(0,25px,0)
    }

    75% {
        -webkit-transform: translate3d(0,-10px,0);
        transform: translate3d(0,-10px,0)
    }

    90% {
        -webkit-transform: translate3d(0,5px,0);
        transform: translate3d(0,5px,0)
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_bounceTop {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(0,-200px,0);
        transform: translate3d(0,-200px,0)
    }

    60% {
        -webkit-transform: translate3d(0,25px,0);
        transform: translate3d(0,25px,0)
    }

    75% {
        -webkit-transform: translate3d(0,-10px,0);
        transform: translate3d(0,-10px,0)
    }

    90% {
        -webkit-transform: translate3d(0,5px,0);
        transform: translate3d(0,5px,0)
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.bounceTop {
    -webkit-animation-name: et_pb_bounceTop;
    animation-name: et_pb_bounceTop
}

@-webkit-keyframes et_pb_bounceRight {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(200px,0,0);
        transform: translate3d(200px,0,0)
    }

    60% {
        -webkit-transform: translate3d(-25px,0,0);
        transform: translate3d(-25px,0,0)
    }

    75% {
        -webkit-transform: translate3d(10px,0,0);
        transform: translate3d(10px,0,0)
    }

    90% {
        -webkit-transform: translate3d(-5px,0,0);
        transform: translate3d(-5px,0,0)
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_bounceRight {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(200px,0,0);
        transform: translate3d(200px,0,0)
    }

    60% {
        -webkit-transform: translate3d(-25px,0,0);
        transform: translate3d(-25px,0,0)
    }

    75% {
        -webkit-transform: translate3d(10px,0,0);
        transform: translate3d(10px,0,0)
    }

    90% {
        -webkit-transform: translate3d(-5px,0,0);
        transform: translate3d(-5px,0,0)
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.bounceRight {
    -webkit-animation-name: et_pb_bounceRight;
    animation-name: et_pb_bounceRight
}

@-webkit-keyframes et_pb_bounceBottom {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(0,200px,0);
        transform: translate3d(0,200px,0)
    }

    60% {
        -webkit-transform: translate3d(0,-20px,0);
        transform: translate3d(0,-20px,0)
    }

    75% {
        -webkit-transform: translate3d(0,10px,0);
        transform: translate3d(0,10px,0)
    }

    90% {
        -webkit-transform: translate3d(0,-5px,0);
        transform: translate3d(0,-5px,0)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes et_pb_bounceBottom {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(0,200px,0);
        transform: translate3d(0,200px,0)
    }

    60% {
        -webkit-transform: translate3d(0,-20px,0);
        transform: translate3d(0,-20px,0)
    }

    75% {
        -webkit-transform: translate3d(0,10px,0);
        transform: translate3d(0,10px,0)
    }

    90% {
        -webkit-transform: translate3d(0,-5px,0);
        transform: translate3d(0,-5px,0)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

.et_animated.bounceBottom {
    -webkit-animation-name: et_pb_bounceBottom;
    animation-name: et_pb_bounceBottom
}

@-webkit-keyframes et_pb_bounceLeft {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(-200px,0,0);
        transform: translate3d(-200px,0,0)
    }

    60% {
        -webkit-transform: translate3d(25px,0,0);
        transform: translate3d(25px,0,0)
    }

    75% {
        -webkit-transform: translate3d(-10px,0,0);
        transform: translate3d(-10px,0,0)
    }

    90% {
        -webkit-transform: translate3d(5px,0,0);
        transform: translate3d(5px,0,0)
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_bounceLeft {
    0%,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        -webkit-transform: translate3d(-200px,0,0);
        transform: translate3d(-200px,0,0)
    }

    60% {
        -webkit-transform: translate3d(25px,0,0);
        transform: translate3d(25px,0,0)
    }

    75% {
        -webkit-transform: translate3d(-10px,0,0);
        transform: translate3d(-10px,0,0)
    }

    90% {
        -webkit-transform: translate3d(5px,0,0);
        transform: translate3d(5px,0,0)
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.bounceLeft {
    -webkit-animation-name: et_pb_bounceLeft;
    animation-name: et_pb_bounceLeft
}

@-webkit-keyframes et_pb_zoom {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

@keyframes et_pb_zoom {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

.et_animated.zoom {
    -webkit-animation-name: et_pb_zoom;
    animation-name: et_pb_zoom
}

@-webkit-keyframes et_pb_zoomTop {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

@keyframes et_pb_zoomTop {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

.et_animated.zoomTop {
    -webkit-animation-name: et_pb_zoomTop;
    animation-name: et_pb_zoomTop;
    -webkit-transform-origin: top;
    transform-origin: top
}

@-webkit-keyframes et_pb_zoomRight {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

@keyframes et_pb_zoomRight {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

.et_animated.zoomRight {
    -webkit-animation-name: et_pb_zoomRight;
    animation-name: et_pb_zoomRight;
    -webkit-transform-origin: right;
    transform-origin: right
}

@-webkit-keyframes et_pb_zoomBottom {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

@keyframes et_pb_zoomBottom {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

.et_animated.zoomBottom {
    -webkit-animation-name: et_pb_zoomBottom;
    animation-name: et_pb_zoomBottom;
    -webkit-transform-origin: bottom;
    transform-origin: bottom
}

@-webkit-keyframes et_pb_zoomLeft {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

@keyframes et_pb_zoomLeft {
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1
    }
}

.et_animated.zoomLeft {
    -webkit-animation-name: et_pb_zoomLeft;
    animation-name: et_pb_zoomLeft;
    -webkit-transform-origin: left;
    transform-origin: left
}

@-webkit-keyframes et_pb_flip {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0);
        transform: rotateX(0)
    }
}

@keyframes et_pb_flip {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0);
        transform: rotateX(0)
    }
}

.et_animated.flip {
    -webkit-animation-name: et_pb_flip;
    animation-name: et_pb_flip
}

@-webkit-keyframes et_pb_flipTop {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0);
        transform: rotateX(0)
    }
}

@keyframes et_pb_flipTop {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0);
        transform: rotateX(0)
    }
}

.et_animated.flipTop {
    -webkit-animation-name: et_pb_flipTop;
    animation-name: et_pb_flipTop;
    -webkit-transform-origin: center;
    transform-origin: center
}

@-webkit-keyframes et_pb_flipRight {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0);
        transform: rotateY(0)
    }
}

@keyframes et_pb_flipRight {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0);
        transform: rotateY(0)
    }
}

.et_animated.flipRight {
    -webkit-animation-name: et_pb_flipRight;
    animation-name: et_pb_flipRight;
    -webkit-transform-origin: center;
    transform-origin: center
}

@-webkit-keyframes et_pb_flipBottom {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0);
        transform: rotateX(0)
    }
}

@keyframes et_pb_flipBottom {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0);
        transform: rotateX(0)
    }
}

.et_animated.flipBottom {
    -webkit-animation-name: et_pb_flipBottom;
    animation-name: et_pb_flipBottom;
    -webkit-transform-origin: center;
    transform-origin: center
}

@-webkit-keyframes et_pb_flipLeft {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0);
        transform: rotateY(0)
    }
}

@keyframes et_pb_flipLeft {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0);
        transform: rotateY(0)
    }
}

.et_animated.flipLeft {
    -webkit-animation-name: et_pb_flipLeft;
    animation-name: et_pb_flipLeft;
    -webkit-transform-origin: center;
    transform-origin: center
}

@-webkit-keyframes et_pb_fold {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0);
        transform: rotateY(0)
    }
}

@keyframes et_pb_fold {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0);
        transform: rotateY(0)
    }
}

.et_animated.fold {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-animation-name: et_pb_fold;
    animation-name: et_pb_fold
}

@-webkit-keyframes et_pb_foldTop {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0);
        transform: rotateX(0)
    }
}

@keyframes et_pb_foldTop {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0);
        transform: rotateX(0)
    }
}

.et_animated.foldTop {
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-animation-name: et_pb_foldTop;
    animation-name: et_pb_foldTop
}

@-webkit-keyframes et_pb_foldRight {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0);
        transform: rotateY(0)
    }
}

@keyframes et_pb_foldRight {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0);
        transform: rotateY(0)
    }
}

.et_animated.foldRight {
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-animation-name: et_pb_foldRight;
    animation-name: et_pb_foldRight
}

@-webkit-keyframes et_pb_foldBottom {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0);
        transform: rotateX(0)
    }
}

@keyframes et_pb_foldBottom {
    to {
        opacity: 1;
        -webkit-transform: rotateX(0);
        transform: rotateX(0)
    }
}

.et_animated.foldBottom {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-animation-name: et_pb_foldBottom;
    animation-name: et_pb_foldBottom
}

@-webkit-keyframes et_pb_foldLeft {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0);
        transform: rotateY(0)
    }
}

@keyframes et_pb_foldLeft {
    to {
        opacity: 1;
        -webkit-transform: rotateY(0);
        transform: rotateY(0)
    }
}

.et_animated.foldLeft {
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-animation-name: et_pb_foldLeft;
    animation-name: et_pb_foldLeft
}

@-webkit-keyframes et_pb_roll {
    0% {
        -webkit-transform-origin: center;
        transform-origin: center
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_roll {
    0% {
        -webkit-transform-origin: center;
        transform-origin: center
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.roll {
    -webkit-animation-name: et_pb_roll;
    animation-name: et_pb_roll
}

@-webkit-keyframes et_pb_rollTop {
    0% {
        -webkit-transform-origin: top;
        transform-origin: top
    }

    to {
        -webkit-transform-origin: top;
        transform-origin: top;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_rollTop {
    0% {
        -webkit-transform-origin: top;
        transform-origin: top
    }

    to {
        -webkit-transform-origin: top;
        transform-origin: top;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.rollTop {
    -webkit-animation-name: et_pb_rollTop;
    animation-name: et_pb_rollTop
}

@-webkit-keyframes et_pb_rollRight {
    0% {
        -webkit-transform-origin: right;
        transform-origin: right
    }

    to {
        -webkit-transform-origin: right;
        transform-origin: right;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_rollRight {
    0% {
        -webkit-transform-origin: right;
        transform-origin: right
    }

    to {
        -webkit-transform-origin: right;
        transform-origin: right;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.rollRight {
    -webkit-animation-name: et_pb_rollRight;
    animation-name: et_pb_rollRight
}

@-webkit-keyframes et_pb_rollBottom {
    0% {
        -webkit-transform-origin: bottom;
        transform-origin: bottom
    }

    to {
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_rollBottom {
    0% {
        -webkit-transform-origin: bottom;
        transform-origin: bottom
    }

    to {
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.rollBottom {
    -webkit-animation-name: et_pb_rollBottom;
    animation-name: et_pb_rollBottom
}

@-webkit-keyframes et_pb_rollLeft {
    0% {
        -webkit-transform-origin: left;
        transform-origin: left
    }

    to {
        -webkit-transform-origin: left;
        transform-origin: left;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes et_pb_rollLeft {
    0% {
        -webkit-transform-origin: left;
        transform-origin: left
    }

    to {
        -webkit-transform-origin: left;
        transform-origin: left;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.et_animated.rollLeft {
    -webkit-animation-name: et_pb_rollLeft;
    animation-name: et_pb_rollLeft
}
